import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Layout from '../components/layout'
import * as styles from '../styles/blog.module.css'

const Blog = ({ data }) => {
  const breakpoints = useBreakpoint()

  const blogPost = data.contentfulBlogPost
  const allBlogs = data.allContentfulBlogPost.edges

  return (
    <Layout
      isHome={false}
      title={blogPost.title}
      description={blogPost.description.description}
      image={`https:${blogPost.thumbnail.file.url}`}
      article={true}
    >
      <div className={styles.blogContainer}>
        <h1 className={styles.blogPageTitle}>Mond blog</h1>
        <hr className={styles.blogHr}/>
        <div className={styles.blogGrid}>
          <div className={styles.blogBody}>
            <div className={styles.blogImageWrapper}>
              <img className={styles.blogImage} alt={blogPost.title} src={blogPost.thumbnail.file.url}/>
              {blogPost.tag && <div className={styles.blogTag}>{`#${blogPost.tag.title}`}</div>}
            </div>
            <p className={styles.blogDate}>{blogPost.publishedDate}</p>
            <h1 className={styles.blogTitle}>{blogPost.title}</h1>
            <hr className={styles.blogBodyHr}/>
            <div className={styles.blogContent}>
              <MDXRenderer>{blogPost.content.childMdx.body}</MDXRenderer>
            </div>
          </div>
          {breakpoints.xs || breakpoints.sm || breakpoints.md
            ? <hr className={styles.blogMobileHr}/>
            : null
          }
          <div className={styles.sideBar}>
            <h2 className={styles.sideBarLatestTitle}>
              Latest blog<span className={styles.sideBarLatestTitleJp}>最新記事</span>
            </h2>
            <hr className={styles.sideBarHr}/>
            {allBlogs.map(({ node: post }, index) => (
              index < 3 &&
              <div key={post.id} className={styles.sideBarBlogsCard}>
                <Link to={`/blog/${post.slug}`} className={styles.sideBarBlogsCardImageWrapper}>
                  <img
                    className={styles.sideBarBlogsCardImage}
                    alt={post.title}
                    src={post.thumbnail.file.url}
                  />
                </Link>
                <div className={styles.sideBarBlogsCardBody}>
                  {post.tag && <div className={styles.sideBarBlogsCardTag}>{`#${post.tag.title}`}</div>}
                  <h2 className={styles.sideBarBlogsCardTitle}>{post.title}</h2>
                  <p className={styles.sideBarBlogsCardDate}>{post.publishedDate}</p>
                </div>
              </div>
            ))}
            <h2 className={styles.sideBarRelatedTitle}>
              Related blog<span className={styles.sideBarRelatedTitleJp}>関連記事</span>
            </h2>
            <hr className={styles.sideBarHr}/>
            {allBlogs.map(({ node: post }) => (
              post.tag && blogPost.tag && post.tag.slug === blogPost.tag.slug &&
              <div key={post.id} className={styles.sideBarBlogsCard}>
                <Link to={`/blog/${post.slug}`} className={styles.sideBarBlogsCardImageWrapper}>
                  <img
                    className={styles.sideBarBlogsCardImage}
                    alt={post.title}
                    src={post.thumbnail.file.url}
                  />
                </Link>
                <div className={styles.sideBarBlogsCardBody}>
                  {post.tag && <div className={styles.sideBarBlogsCardTag}>{`#${post.tag.title}`}</div>}
                  <h2 className={styles.sideBarBlogsCardTitle}>{post.title}</h2>
                  <p className={styles.sideBarBlogsCardDate}>{post.publishedDate}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {breakpoints.xs || breakpoints.sm || breakpoints.md
          ? null
          : <hr className={styles.blogHr}/>
        }
      </div>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query BlogQuery($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      slug
      publishedDate(formatString: "YYYY.MM.DD")
      description {
        description
      }
      thumbnail {
        file {
          url
        }
      }
      content {
        childMdx {
          body
        }
      }
      tag {
        title
        slug
      }
    }
    allContentfulBlogPost(
      filter: { slug: { ne: $slug } }
      sort: { fields: publishedDate, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          publishedDate(formatString: "YYYY.MM.DD")
          thumbnail {
            file {
              url
            }
          }
          tag {
            title
            slug
          }
        }
      }
    }
  }
`