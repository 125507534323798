// extracted by mini-css-extract-plugin
export var blogBody = "blog-module--blog-body--f10ab";
export var blogBodyHr = "blog-module--blog-body-hr--c17f4";
export var blogContainer = "blog-module--blog-container--4b306";
export var blogContent = "blog-module--blog-content--55ed8";
export var blogDate = "blog-module--blog-date--518be";
export var blogGrid = "blog-module--blog-grid--02cb8";
export var blogHr = "blog-module--blog-hr--0165f";
export var blogImage = "blog-module--blog-image--d20bf";
export var blogImageWrapper = "blog-module--blog-image-wrapper--fbe6e";
export var blogMobileHr = "blog-module--blog-mobile-hr--27b7f";
export var blogPageTitle = "blog-module--blog-page-title--6d318";
export var blogTag = "blog-module--blog-tag--393bb";
export var blogTitle = "blog-module--blog-title--6c47d";
export var sideBar = "blog-module--side-bar--76828";
export var sideBarBlogsCard = "blog-module--side-bar-blogs-card--d03db";
export var sideBarBlogsCardBody = "blog-module--side-bar-blogs-card-body--d0fd1";
export var sideBarBlogsCardDate = "blog-module--side-bar-blogs-card-date--2c6fd";
export var sideBarBlogsCardImage = "blog-module--side-bar-blogs-card-image--3c8c0";
export var sideBarBlogsCardImageWrapper = "blog-module--side-bar-blogs-card-image-wrapper--74520";
export var sideBarBlogsCardTag = "blog-module--side-bar-blogs-card-tag--bb26f";
export var sideBarBlogsCardTitle = "blog-module--side-bar-blogs-card-title--3c5db";
export var sideBarHr = "blog-module--side-bar-hr--58b04";
export var sideBarLatestTitle = "blog-module--side-bar-latest-title--ef7b3";
export var sideBarLatestTitleJp = "blog-module--side-bar-latest-title-jp--e9709";
export var sideBarRelatedTitle = "blog-module--side-bar-related-title--c5486";
export var sideBarRelatedTitleJp = "blog-module--side-bar-related-title-jp--1f571";